<script setup lang="ts">
import { Card, Icon } from 'webcc-ui-components'
import AccessibleIconButton from '~/components/basic/AccessibleIconButton.vue'

withDefaults(
  defineProps<{
    prefixIcon?: string
    suffixIcon?: string
    disabled?: boolean
    ariaLabelIcon?: string
    contentClass?: string | null
  }>(),
  {
    prefixIcon: undefined,
    suffixIcon: undefined,
    ariaLabelIcon: undefined,
    disabled: false,
    contentClass: null,
  },
)

const emit = defineEmits<{
  (e: 'click'): void
}>()

function handleClick() {
  emit('click')
}

function handleKeyDown(evt: KeyboardEvent) {
  if (evt.code === 'Enter') {
    emit('click')
  }
}
</script>

<template>
  <Card
    class="[&&]:p-4"
    :class="{
      '[&&]:cursor-not-allowed': disabled,
    }"
    role="button"
    :tabindex="disabled ? null : 0"
    :aria-disabled="disabled"
    v-on="disabled ? {} : { click: handleClick, onKeyDown: handleKeyDown }"
  >
    <div
      class="w-full flex gap-x-4 items-center"
      :class="{ 'select-none': disabled }"
    >
      <slot name="prefix-icon">
        <Icon v-if="prefixIcon" size="lg" :graphic="prefixIcon" />
      </slot>

      <div
        class="w-full flex flex-col gap-y-1"
        :class="[contentClass, { 'text-txt-300': disabled }]"
      >
        <slot />
      </div>

      <slot name="suffix-icon">
        <AccessibleIconButton
          v-if="suffixIcon"
          :disabled="disabled"
          :label="ariaLabelIcon"
          :graphic="suffixIcon"
          :icon-class="{
            'text-txt-500': disabled,
          }"
        />
      </slot>
    </div>
  </Card>
</template>
